<template>
  <div class="mb-2">
    <div class="my-1">
      <span style="font-size: 1.1rem" class="text-primary">
        SELECT THE REASON CODE FOR THE CHARGEBACK
      </span>
    </div>
    <validation-observer ref="form_merchant" class="w-100 mt-2" tag="div">
      <b-row>
        <b-table
          ref="cardsList"
          no-border-collapse
          class="position-relative w-100"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="45vh"
          primary-key="id"
          table-class="text-nowrap "
          responsive="sm"
          :items="dataTransactions"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="isBusy"
          :per-page="paginationProps.perPage"
          :current-page="currentPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(client)="data">
            <span class="text-primary">{{ data.item.client }}</span>
            <div>{{ data.item.account }}</div>
            <status-account :account="data.item" :text="true"></status-account>
          </template>

          <template #cell(merchant_description)="data">
            <span :class="defineColorMerchant(data.item.merchant)">{{
              data.item.merchant_description
            }}</span>

            <div>AUTH CODE: {{ data.item.auth_code }}</div>
          </template>

          <template #cell(amount)="data"> $ {{ data.item.amount }} </template>

          <template #cell(authorize_transaction_id)="data">
            {{ data.item.authorize_transaction_id
            }}<b> ({{ data.item.trans_name }})</b>
            <div>{{ data.item.date | myGlobalWithHour }}</div>
          </template>

          <template #cell(reason_code)="data">
            <div>
              <ValidationProvider
                rules="required"
                v-slot="{ valid, validated }"
                class="w-100"
              >
                <b-form-select
                  v-model="data.item.reason_code"
                  :options="reasonCodeList"
                  variant="primary"
                  :state="!validated ? null : valid"
                  value-field="id"
                  text-field="title"
                >
                  <template #first>
                    <b-form-select-option :value="null"
                      >Not available reason code
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </ValidationProvider>
            </div>
          </template>
          <template #cell(action)="data">
            <feather-icon
              v-if="dataTransactions.length > 1"
              icon="TrashIcon"
              class="cursor-pointer text-danger"
              size="18"
              @click="removeItem(data.item)"
            />
          </template>
        </b-table>
      </b-row>
      <hr />
      <b-row class="mt-2 mx-1">
        <div class="w-100">
          <b-button
            variant="primary"
            size="md"
            class="float-right"
            @click="validateForm()"
            :disabled="reasonCodeList.length > 0 ? false : true"
          >
            SAVE
          </b-button>
          <b-button
            variant="secondary"
            size="md"
            @click="returnModal()"
            class="float-right mr-1"
          >
            RETURN
          </b-button>
        </div>
      </b-row>
    </validation-observer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
//service
import chargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service.js";

import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  components: {
    StatusAccount,
  },
  props: {
    reasonCodeList: { required: false, type: Array },
    dataTransaction: { required: false },
    dataTransactions: { required: false, type: Array },
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: "client",
          label: "CLIENT",
        },
        {
          key: "merchant_description",
          label: "MERCHANT",
        },
        {
          key: "amount",
          label: "AMOUNT",
        },
        {
          key: "authorize_transaction_id",
          label: "TRANSACTION",
        },
        {
          key: "reason_code",
          label: "REASON CODE",
        },
        {
          key: "action",
          label: "ACTION",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      sortBy: "select",
      sortDesc: true,
      isBusy: false,
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      getRefreshTableChargeBackStatus:
        "ChargeBackStoreV2/G_REFRESH_TABLE_CHARGE_BACK",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    showItems() {
      return [
        {
          name: "ACCOUNT",
          value: this.dataTransaction.account,
        },
        {
          name: "AUTH CODE",
          value: this.dataTransaction.authcode,
        },
        {
          name: "ID TRANSACTION",
          value: this.dataTransaction.authorize_transaction_id,
        },
        {
          name: "CLIENT",
          value: this.dataTransaction.client,
        },
        {
          name: "AMOUNT",
          value: this.dataTransaction.amount,
        },
        {
          name: "DATE",
          value: moment(this.dataTransaction.created_at).format(
            "MM/DD/YYYY hh:mm A"
          ),
        },
      ];
    },
    isProcessTab() {
      return this.$route.matched[2].meta.status == 1;
    },
    paginationProps() {
      return {
        totalRows: this.items.length || 0,
        perPage: 100,
      };
    },
  },
  created() {},
  methods: {
    ...mapActions({
      updateTableRefreshChargeBack:
        "ChargeBackStoreV2/A_REFRESH_TABLE_CHARGE_BACK",
      A_CHARGEBACK_COUNTERS: "ChargeBackStoreV2/A_CHARGEBACK_COUNTERS",
    }),
    async validateForm() {
      const resultForm = await this.$refs.form_merchant.validate();
      if (resultForm) {
        const response = await this.showConfirmSwal();
        if (!response.isConfirmed) {
          return;
        }
        this.addPreloader();
        const params = {
          data: this.dataTransactions,
          created_by: this.currentUser.user_id,
          module_tracking_id: this.moduleId,
        };
        try {
          const results = await chargeBackService.registerChargeBack(params);
          if (results) {
            await this.A_CHARGEBACK_COUNTERS(this.moduleId);
            this.removePreloader();
            this.close();
            this.isProcessTab
              ? this.updateTableRefreshChargeBack(true)
              : this.$router.push({ name: "charge-back-in-process" });
            this.$swal({
              position: "center",
              icon: "success",
              title: "Success",
              text: "created successfully.",
              showConfirmButton: true,
            }).then(async (result) => {
              if (result.value) {
                this.showToast(
                  "success",
                  "top-right",
                  "Congratulations",
                  "CheckIcon",
                  "You've successfully"
                );
              }
            });
          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    close() {
      this.$emit("close");
    },
    defineColorMerchant(merchant) {
      switch (merchant) {
        case 1:
          return "text-primary";
        case 2:
          return "text-info";
        case 3:
          return "text-warning";
        default:
          return "text-success";
      }
    },
    returnModal() {
      this.$emit("returnModal");
    },
    removeItem(item) {
      const index = this.dataTransactions.findIndex(
        (x) => x.authorize_transaction_id == item.authorize_transaction_id
      );
      this.dataTransactions.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.w-35 {
  width: 35% !important;
}
.border-fill {
  border-color: #0090e7 !important;
}
</style>
