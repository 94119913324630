export default [
  {
    key: "client_name",
    label: "Client Name",
    visible: true,
  },
  {
    key: "transaction_id",
    label: "Transaction",
    visible: true,
  },
  {
    key: "auth_code",
    label: "Auth Code",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "responsable",
    label: "Solution",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "amount",
    label: "Amount",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "recovered",
    label: "Recovered",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "created_at",
    label: "Created At",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
]
