<template>
  <b-modal
    v-model="onControl"
    modal-class="modal-primary"
    :title="`Create New Chargeback (STEP ${showMerchant ? 2 : 1} of 2)`"
    title-class="h3 text-white font-weight-bolder"
    size="xlg"
    :hide-footer="showMerchant"
    no-close-on-backdrop
    @hidden="close"
  >
    <b-row
      v-if="!showMerchant"
      class="align-items-baseline"
      style="padding: 0 15px"
    >
      <b-col md="10" sm="12">
        <b-row>
          <b-col md="2" sm="6">
            <b-form-group class="mt-2" label="Last 4 Digits of Card">
              <b-form-input
                v-model="searchDigits"
                v-mask="'####'"
                type="text"
                @keyup.enter="validateAuthorization()"
              />
            </b-form-group>
          </b-col>
          <b-col md="2" sm="6">
            <b-form-group class="mt-2" label="Authorization Code">
              <b-form-input
                v-model="searchCode"
                type="text"
                style="text-transform: uppercase"
                @keyup.enter="validateAuthorization()"
              />
            </b-form-group>
          </b-col>
          <b-col md="2" sm="6">
            <b-form-group class="mt-2" label="Transaction ID">
              <b-form-input
                v-model="searchTransaction"
                type="text"
                @keyup.enter="validateAuthorization()"
              />
            </b-form-group>
          </b-col>
          <b-col md="3" sm="6">
            <b-form-group class="mt-2" label="Merchants">
              <v-select
                v-model="searchMerchant"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :options="merchants"
                :reduce="(option) => option.id"
                :disabled="!isDisableProgram"
              />
            </b-form-group>
          </b-col>
          <b-col md="3" sm="6">
            <b-form-group class="mt-2" label="Programs">
              <v-select
                v-model="searchProgram"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :options="programs"
                :reduce="(option) => option.id"
                :disabled="!isDisableProgram"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="2" sm="12" class="d-flex justify-content-center">
        <b-button
          variant="primary"
          class="w-100"
          @click="validateAuthorization()"
        >
          Search
        </b-button>
      </b-col>
      <b-col v-if="showInfoMessage" cols="12">
        <feather-icon
          icon="AlertTriangleIcon"
          class="text-danger"
          stroke-width="2"
        />
        <span class="text-danger"> No filter is being used </span>
      </b-col>
    </b-row>
    <b-row v-if="!showMerchant">
      <b-col>
        <validation-observer ref="form_table">
          <b-table
            id="cards-list"
            no-border-collapse
            class="blue-scrollbar position-relative table-new-customization"
            :fields="fields"
            show-empty
            no-provider-filtering
            sticky-header="45vh"
            primary-key="id"
            table-class="text-nowrap wo-padding"
            responsive="sm"
            :items="items"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>

            <template #cell(start_date)="data">
              {{ data.item.program_name }}
              <div>{{ data.item.start_date | myGlobalDay }}</div>
            </template>
            <template #cell(amount)="data"> $ {{ data.item.amount }} </template>

            <template #cell(merchant_description)="data">
              <span :class="defineColorMerchant(data.item.merchant)">{{
                data.item.merchant_description
              }}</span>
              <div>AUTH CODE: {{ data.item.auth_code }}</div>
              <div>
                CARD: {{ data.item.card_number.replace(/(.{4})/, "$1-") }}
              </div>
            </template>

            <template #cell(authorize_transaction_id)="data">
              <div>{{ data.item.user_name }}</div>
              <div>
                {{ data.item.authorize_transaction_id
                }}<b> ({{ data.item.trans_name }})</b>
              </div>
              <div>{{ data.item.date | myGlobalWithHour }}</div>
            </template>

            <template #cell(select)="data">
              <ValidationProvider
                v-slot="{ valid, validated }"
                rules="required"
                class="d-flex justify-content-center"
              >
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectTransaction"
                    :value="data.item"
                    variant="danger"
                    :state="!validated ? null : valid"
                    @change="onSelectTransaction(data.item)"
                  />
                </b-form-group>
              </ValidationProvider>
            </template>
            <template #cell(client)="data">
              <span class="text-primary">{{ data.item.client }}</span>
              <template v-if="data.item.account">
                <div>{{ data.item.account }}</div>
                <status-account :account="data.item" :text="true" />
              </template>
              <div v-else>
                <b-badge variant="light-warning"> LEAD </b-badge>
              </div>
            </template>
          </b-table>
        </validation-observer>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        />
      </b-col>
    </b-row>

    <!--::::Component::::::-->
    <merchant-reason-code
      v-if="showMerchant"
      :reason-code-list="reasonCodeList"
      :data-transaction="dataTransaction"
      :data-transactions="selectTransaction"
      @close="close"
      @returnModal="returnModal"
    />

    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-if="!showMerchant"
          variant="primary"
          size="md"
          class="float-right"
          :disabled="selectTransaction.length === 0"
          @click="validateForm()"
        >
          NEXT
        </b-button>
        <b-button
          v-if="!showMerchant"
          variant="secondary"
          size="md"
          class="float-right mr-1"
          @click="close()"
        >
          CANCEL
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
// service
import chargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
import RegisterPaymentService from "@/views/administration/views/register-payment/register-payment.service";

// components
import MerchantReasonCode from "@/views/commons/components/charge-back_v2/view/modal/MerchantReasonCode.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

// fields
import modalCreateChargeFields from "@/views/commons/components/charge-back_v2/data/fields.modal.create.charge.back";

import { mapGetters } from "vuex";

export default {
  components: {
    MerchantReasonCode,
    StatusAccount,
  },
  data() {
    return {
      items: [],
      fields: modalCreateChargeFields,
      sortBy: "created_at",
      sortDesc: true,
      isBusy: false,
      onControl: false,
      selectTransaction: [],
      searchCode: "",
      searchTransaction: "",
      searchDigits: "",
      dataTransaction: [],
      auth_code: "",
      showMerchant: false,
      searchProgram: "",
      searchMerchant: "",
      reasonCodeList: [],
      showInfoMessage: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 20,
      merchants: [],
      programs: [
        { id: 1, text: "Business" },
        { id: 2, text: "Boost Credit" },
        { id: 3, text: "Credit Experts" },
        { id: 4, text: "Debt Solution" },
        { id: 5, text: "Tax Research" },
        { id: 6, text: "Court Info" },
        { id: 7, text: "Specialist" },
        { id: 8, text: "KeyBook" },
        { id: 9, text: "Paragon" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isDisableProgram() {
      return (
        this.searchCode !== "" ||
        this.searchDigits !== "" ||
        this.searchTransaction !== ""
      );
    },
  },
  watch: {
    currentPage(newVal, oldVal) {
      if (newVal !== oldVal) this.validateAuthorization(newVal);
    },
  },
  async created() {
    this.onControl = true;
    this.getReasonCode();
    this.getAllMerchants();
  },

  methods: {
    close() {
      this.$emit("close");
    },
    async validateForm() {
      if (this.items.length > 0) {
        const resultForm = await this.$refs.form_table.validate();
        if (resultForm) {
          this.showMerchant = true;
        }
      }
    },
    async validateAuthorization(current_page = 1) {
      const params = {
        auth_code: this.searchCode,
        transaction_id: this.searchTransaction,
        digits: this.searchDigits,
        program_id: this.searchProgram,
        merchant_id: this.searchMerchant,
        per_page: this.perPage,
        current_page,
      };
      if (
        params.auth_code ||
        params.transaction_id ||
        params.digits ||
        params.program_id
      ) {
        try {
          this.isBusy = true;
          const results = await chargeBackService.getTransactionsByAuthcode(
            params
          );
          this.items = results.data?.map((item) => {
            item.reason_code = null;
            return item;
          });
          this.totalRows = results.total;
          this.currentPage = results.current_page;
          this.perPage = results.per_page;
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.isBusy = false;
          this.showInfoMessage = false;
        }
      } else {
        this.items = [];
        this.showInfoMessage = true;
      }
    },
    async getReasonCode() {
      this.addPreloader();
      const result = await chargeBackService.getReasonCodeListChargeBack();
      if (result) {
        this.reasonCodeList = result;
      }
      this.removePreloader();
    },
    onSelectTransaction(item) {
      this.dataTransaction = item;
    },
    returnModal() {
      this.selectTransaction.forEach((item) => {
        item.reason_code = null;
      });
      this.showMerchant = false;
    },
    defineColorMerchant(merchant) {
      switch (merchant) {
        case 1:
          return "text-primary";
        case 2:
          return "text-info";
        case 3:
          return "text-warning";
        default:
          return "text-success";
      }
    },
    async getAllMerchants() {
      try {
        const { data } = await RegisterPaymentService.getAllMerchants();
        this.merchants = data.map((merchant) => ({
          id: merchant.id,
          text: merchant.description.replace(/ *\([^)]*\) */g, ""),
        }));
      } catch (error) {
        console.error(error, "error");
      }
    },
  },
};
</script>
