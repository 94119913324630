<template>
  <div ref="chargebackMain">
    <b-container class="m-0 w-100" fluid>
      <header-slot :chargeBack="true">
        <template #actions>
          <div
            class="d-flex justify-content-end align-items-center"
            style="gap: 15px"
          >
            <b-button
              variant="info"
              v-if="
                moduleId === 16 && (isCeo || isChief || isSupervisor) && false
              "
              @click="openImportExcelModal = true"
            >
              <div class="d-flex" style="gap: 5px">
                <feather-icon icon="UploadCloudIcon" size="15" />
                Import
              </div>
            </b-button>
            <b-button
              variant="success"
              v-if="moduleId === 16 && (isCeo || isChief || isSupervisor)"
              @click="openCreateChargeBackModal()"
              ><div class="d-flex" style="gap: 5px">
                <feather-icon icon="PlusIcon" size="15" />
                Create
              </div>
            </b-button>
            <div
              v-if="moduleId != 16"
              class="d-flex justify-content-end cursor-pointer"
              @click="openDiscountDetailsModal(1)"
            >
              <div class="av-balance-border-red float-right d-flex">
                <div class="av-balance-text-red text-center">
                  Total Penalties
                </div>
                <h2 class="av-balance-content-red">
                  ${{ totalPenalty ? formatPrice(totalPenalty) : 0.0 }}
                </h2>
              </div>
            </div>
            <div
              v-if="moduleId != 16"
              class="d-flex justify-content-end cursor-pointer"
              @click="openDiscountDetailsModal(2)"
            >
              <div class="av-balance-border-red float-right d-flex">
                <div class="av-balance-text-red text-center">
                  Total Chargebacks
                </div>
                <h2 class="av-balance-content-red">
                  ${{ totalChargeBacks ? formatPrice(totalChargeBacks) : 0.0 }}
                </h2>
              </div>
            </div>
            <div
              v-if="moduleId == 16"
              class="d-flex justify-content-end cursor-pointer"
              @click="openDiscountModal()"
            >
              <div class="av-balance-border-red float-right d-flex">
                <div class="av-balance-text-red text-center">
                  Total Chargebacks
                </div>
                <h2 class="av-balance-content-red">
                  ${{ totalDiscounts ? formatPrice(totalDiscounts) : 0.0 }}
                </h2>
              </div>
            </div>
          </div>
        </template>
      </header-slot>
      <b-nav card-header pills class="m-0">
        <b-nav-item
          :to="{ name: $route.matched[1].meta.routeInPending }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          >PENDING
          <!-- old in process -->
          <span v-if="counterInProcess > 0" class="ml-1">
            <feather-icon
              icon
              :badge="counterInProcess > 99 ? '99+' : counterInProcess"
              badge-classes="badge-danger"
            />
          </span>
        </b-nav-item>
        <b-nav-item
          :to="{ name: $route.matched[1].meta.routeInProcess }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          :active="isTabSolution || isTabDispute"
          >IN PROCESS
          <!-- old in solution -->
          <span v-if="counterPending > 0" class="ml-1">
            <feather-icon
              icon
              :badge="counterPending > 99 ? '99+' : counterPending"
              badge-classes="badge-danger"
            />
          </span>
        </b-nav-item>
        <b-nav-item
          :to="{ name: $route.matched[1].meta.routeCompleted }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          >COMPLETED
        </b-nav-item>
      </b-nav>
      <b-card
        no-body
        class="border-top-primary border-3 border-table-radius px-0"
      >
        <router-view :key="key" />
      </b-card>

      <ModalCreateChargeBack
        v-if="openCreateChargeBackModalController"
        @close="closeCreateChargeBackModal"
      />

      <DiscountChargeBackModal
        v-if="openDiscountChargeBackModal"
        @close="closeDiscountModal"
      />

      <DiscountDetailsModal
        v-if="openDiscountDetailsModalController"
        :typeDetail="typeDetail"
        :moduleDiscount="moduleId"
        @close="closeDiscountDetailsModal"
      />
      <b-modal
        v-model="openImportExcelModal"
        title="Import excel"
        ok-title="Import"
        scrollable
        @hidden="openImportExcelModal = false"
        @ok.prevent="saveExcel"
      >
        <validation-observer ref="form">
          <validation-provider
            v-slot="{ errors, valid }"
            vid="current"
            name="current"
            rules="required"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group label="File">
                  <b-form-file
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="file"
                    placeholder="Choose an excel..."
                    drop-placeholder="Choose an excel..."
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-provider>
        </validation-observer>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import ModalCreateChargeBack from "@/views/commons/components/charge-back_v2/view/modal/ModalCreateChargeBack.vue";
import chargeBackV2Service from "@/views/commons/components/charge-back_v2/service/charge.back.service.js";
import DiscountChargeBackModal from "@/views/commons/components/charge-back_v2/view/modal/DiscountChargeBackModal.vue";
import DiscountDetailsModal from "@/views/commons/components/charge-back_v2/view/modal/DiscountDetailsModal.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ModalCreateChargeBack,
    DiscountChargeBackModal,
    DiscountDetailsModal,
  },
  data() {
    return {
      openCreateChargeBackModalController: false,
      module: "",
      key: 0,
      counters: {
        inProcess: 0,
        solution: 0,
        dispute: 0,
      },
      openDiscountChargeBackModal: false,
      openDiscountDetailsModalController: false,
      typeDetail: 0,
      openImportExcelModal: false,
      file: null,
    };
  },
  async created() {
    await Promise.all([
      this.A_CHARGEBACK_COUNTERS(this.moduleId),
      this.getDiscounts(this.moduleId),
    ]);
  },
  computed: {
    ...mapGetters({
      totalPenalty: "ChargeBackStoreV2/G_CHARGEBACK_PENALTY_DISCOUNTS",
      totalDiscounts: "ChargeBackStoreV2/G_CHARGEBACK_TOTAL_DISCOUNTS",
      totalChargeBacks: "ChargeBackStoreV2/G_CHARGEBACK_TOTAL_CHARGEBACKS",
      counterInProcess: "ChargeBackStoreV2/G_IN_PROCCESS_CHARGEBACK_COUNTER",
      counterRemovecb: "ChargeBackStoreV2/G_REMOVE_CHARGEBACK_COUNTER",
      counterPaymentClient:
        "ChargeBackStoreV2/G_PAYMENT_CLIENT_CHARGEBACK_COUNTER",
      counterSolution: "ChargeBackStoreV2/G_SOLUTION_CHARGEBACK_COUNTER",
      counterPending: "ChargeBackStoreV2/G_PENDING_CHARGEBACK_COUNTER",
      counterObserved: "ChargeBackStoreV2/G_OBSERVED_CHARGEBACK_COUNTER",
      counterSent: "ChargeBackStoreV2/G_SENT_CHARGEBACK_COUNTER",
      counterDispute: "ChargeBackStoreV2/G_DISPUTE_CHARGEBACK_COUNTER",
      currentUser: "auth/currentUser",
    }),

    isTabSolution() {
      const { routeRemove, routePayment, routeDepartment } =
        this.$route.matched[2].meta;
      return [routeRemove, routePayment, routeDepartment].includes(
        this.$route.name
      );
    },
    isTabDispute() {
      if (!this.$route.matched[3]) return false;
      const { routePending, routeObserved, routeSent } =
        this.$route.matched[3].meta;
      return [routePending, routeObserved, routeSent].includes(
        this.$route.name
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module == 28
        ? 11
        : this.$route.matched[0].meta.module;
    },
  },
  methods: {
    ...mapActions({
      getDiscounts: "ChargeBackStoreV2/A_CHARGEBACK_TOTAL_DISCOUNTS",
      A_CHARGEBACK_COUNTERS: "ChargeBackStoreV2/A_CHARGEBACK_COUNTERS",
    }),
    openCreateChargeBackModal() {
      this.openCreateChargeBackModalController = true;
    },
    closeCreateChargeBackModal() {
      this.openCreateChargeBackModalController = false;
    },
    openDiscountModal() {
      this.openDiscountChargeBackModal = true;
    },
    closeDiscountModal() {
      this.typeDetail = 0;
      this.openDiscountChargeBackModal = false;
    },
    openDiscountDetailsModal(type = 0) {
      this.typeDetail = type;
      this.openDiscountDetailsModalController = true;
    },
    closeDiscountDetailsModal() {
      this.openDiscountDetailsModalController = false;
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async getTabCounters() {
      const { in_process, remove_cb, payment_client, pending, observed } =
        await chargeBackV2Service.getChargebackCounters({
          module_id: module,
          status: module === 16 ? 1 : 3,
        });

      this.counters.inProcess = in_process;
      this.counters.solution = remove_cb + payment_client;
      this.counters.dispute = pending + observed;
    },
    async saveExcel() {
      const validate = await this.$refs.form.validate();
      if (!validate) return;
      const response = await this.showConfirmSwal(
        "Are you sure you want to import the excel?"
      );
      if (!response.value) return;
      try {
        this.addPreloader();
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("user_id", this.currentUser.user_id);
        await marketingLettersService.importMarketingLettersExcel(formData);
        this.showSuccessSwal();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
  watch: {
    "$route.name"(newVal) {
      this.key = newVal;
    },
  },
};
</script>
<style scoped>
.av-balance-border-red {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #e30613;
}
.av-balance-text-red {
  color: white;
  background-color: #e30613;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}
.av-balance-content-red {
  color: white;
  background-color: #e83b4c;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
</style>
