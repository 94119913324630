export default [
  {
    label: "Select",
    key: "select",
    thClass: "text-center mx-0 px-0",
    tdClass: "justify-content-center",
  },
  {
    key: "client",
    label: "CLIENT",
  },
  {
    key: "start_date",
    label: "START DATE",
  },
  // {
  //   key: "card_number",
  //   label: "CREDIT CARD",
  // },
  {
    key: "merchant_description",
    label: "MERCHANT DESCRIPTION",
  },
  {
    key: "authorize_transaction_id",
    label: "TRANSACTION",
  },
  {
    key: "amount",
    label: "AMOUNT",
  },
]
