<template>
  <b-modal
    v-model="onControl"
    title="Departments Discounts"
    title-tag="h3"
    modal-class="modal-primary"
    @hidden="close"
    size="xmd"
    scrollable
    hide-footer
  >
    <b-table
      id="discounts-list"
      ref="discountsList"
      no-border-collapse
      class="position-relative"
      :fields="fields"
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :items="discountsProvider"
      :busy="isBusy"
    >
      <template #cell(department)="data">
        {{ data.item.program.toUpperCase() }}
      </template>

      <template #cell(total)="data">
        <b-badge variant="light-danger">
          $
          {{
            (Number(data.item.total_penalty) +
              Number(data.item.total_chargeback))
              | currency
          }}
        </b-badge>
      </template>
      <template #cell(total_penalty)="data">
        <b-badge variant="light-danger">
          $
          {{ data.item.total_penalty | currency }}
        </b-badge>
      </template>
      <template #cell(total_chargeback)="data">
        <b-badge variant="light-danger">
          $
          {{ data.item.total_chargeback | currency }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <feather-icon
          class="pointer text-primary"
          icon="EyeIcon"
          size="18"
          v-b-tooltip
          title="See discount details"
          @click="openDiscountDetailsModal(data.item.module_id)"
        />
      </template>
    </b-table>

    <discount-details-modal
      v-if="openDiscountDetailsModalController"
      :moduleDiscount="moduleDiscount"
      @close="closeDiscountDetailsModal"
    />
  </b-modal>
</template>

<script>
import fields from "@/views/commons/components/charge-back_v2/data/fields.modal.discount.charge.back"
import chargeBackV2Service from "@/views/commons/components/charge-back_v2/service/charge.back.service.js"
import DiscountDetailsModal from "@/views/commons/components/charge-back_v2/view/modal/DiscountDetailsModal.vue"

export default {
  props: {},
  components: {
    DiscountDetailsModal,
  },
  data() {
    return {
      onControl: false,
      items: [],
      fields: fields,
      isBusy: false,
      openDiscountDetailsModalController: false,
      moduleDiscount: null,
    }
  },

  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module
    },
  },
  created() {
    this.onControl = true
  },
  mounted() {},
  methods: {
    openDiscountDetailsModal(module) {
      this.moduleDiscount = module
      this.openDiscountDetailsModalController = true
    },
    closeDiscountDetailsModal() {
      this.openDiscountDetailsModalController = false
    },
    close() {
      this.$emit("close")
    },

    async discountsProvider() {
      try {
        const { data } = await chargeBackV2Service.getAllDiscounts({})
        this.items = data
        return this.items || []
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
