export default [
  {
    key: "department",
    label: "Department",
    visible: true,
  },
  {
    key: "total_chargeback",
    label: "Total Chargebacks",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "total_penalty",
    label: "Total Penalty",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "total",
    label: "Total",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "actions",
    label: "Actions",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
]
