<template>
  <b-modal
    v-model="onControl"
    title="Discounts Details"
    title-tag="h3"
    modal-class="modal-primary"
    @hidden="close"
    size="xl"
    scrollable
    hide-footer
  >
    <b-table
      id="client-discounts-list"
      ref="clientDiscountsList"
      no-border-collapse
      class="position-relative"
      :fields="fields"
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :items="items"
      :busy="isBusy"
      :per-page="paginationProps.perPage"
      :current-page="currentPage"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>

      <template #cell(client_name)="data">
        <b-row>
          <b-col>
            <span class="text-center text-primary">
              {{ data.item.client_name }}
            </span>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <span class="text-center">
              {{ data.item.account }}
            </span>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <span>
              <status-account
                :account="data.item"
                :text="true"
              ></status-account>
            </span>
          </b-col>
        </b-row>
      </template>

      <template #cell(responsable)="{ item }">
        <b-badge
          :variant="
            statusChargeback(item.status, item.responsable, item.dispute)
          "
        >
          <span v-if="item.status == 'responsable'">
            {{ `${item.status} : ${item.responsable}`.toUpperCase() }}
          </span>
          <span v-else-if="item.status == 'dispute'">
            {{ `${item.status} : ${item.dispute}`.toUpperCase() }}
          </span>
          <span v-else>
            {{ item.status.toUpperCase() }}
          </span>
        </b-badge>
      </template>

      <template #cell(amount)="data">
        <div class="d-flex flex-column" style="gap: 0.5rem">
          <b-badge
            v-if="
              data.item.amount_penalty != '0.00' && [0, 1].includes(typeDetail)
            "
            :variant="
              data.item.status_recovered == 4
                ? 'light-success'
                : data.item.status_recovered == 3
                ? 'light-warning'
                : 'light-danger'
            "
          >
            PENALTY: $ {{ data.item.amount_penalty | currency }}
          </b-badge>
          <b-badge
            v-if="
              data.item.amount_chargeback != '0.00' &&
              [0, 2].includes(typeDetail)
            "
            :variant="
              data.item.status_recovered == 4
                ? 'light-success'
                : data.item.status_recovered == 2
                ? 'light-warning'
                : 'light-danger'
            "
          >
            CHARGEBACK: $ {{ data.item.amount_chargeback | currency }}
          </b-badge>
        </div>
      </template>

      <template #cell(recovered)="data">
        <feather-icon
          v-if="data.item.status_recovered == 1"
          icon="XCircleIcon"
          size="18"
          class="text-danger"
          v-b-tooltip.hover
          title="No Recovered"
        />
        <feather-icon
          v-else-if="[2, 3].includes(data.item.status_recovered)"
          icon="ClockIcon"
          size="18"
          class="text-warning"
          v-b-tooltip.hover
          :title="
            data.item.status_recovered == 2
              ? 'Chargeback Recovered'
              : 'Penalty Recovered'
          "
        />
        <feather-icon
          v-else
          icon="CheckCircleIcon"
          size="18"
          class="text-success"
          v-b-tooltip.hover
          title="Recovered"
        />
      </template>

      <template #cell(created_at)="data">
        {{ data.item.created_at | myGlobalDay }}
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="paginationProps.totalRows"
      :per-page="paginationProps.perPage"
      align="center"
      aria-controls="client-discounts-list"
    />
  </b-modal>
</template>

<script>
import fields from "@/views/commons/components/charge-back_v2/data/fields.modal.discount.client";
import chargeBackV2Service from "@/views/commons/components/charge-back_v2/service/charge.back.service.js";
import { mapActions } from "vuex";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  props: {
    moduleDiscount: {
      type: Number,
      required: true,
    },
    typeDetail: {
      type: Number,
      default: 0,
    },
  },
  components: {
    StatusAccount,
  },
  data() {
    return {
      onControl: false,
      items: [],
      fields: fields,
      isBusy: false,
      currentPage: 1,
    };
  },

  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    paginationProps() {
      return {
        totalRows: this.items.length || 0,
        perPage: 5,
      };
    },
  },

  created() {
    this.detailsProvider();
    this.onControl = true;
  },
  async mounted() {
    await this.getStatusClient();
  },
  methods: {
    ...mapActions({
      getStatusClient: "DebtSolutionZeroPayment/getStatusAccountAction",
    }),

    close() {
      this.$emit("close");
    },

    async detailsProvider() {
      try {
        const data = await chargeBackV2Service.getDepartmentDiscounts({
          department_id: this.moduleDiscount,
        });
        this.items = data;
      } catch (e) {
        console.log(e);
      }
    },

    statusChargeback(status, responsable, dispute) {
      let text = "";
      switch (status) {
        case "responsable":
          text =
            responsable === "department" ? "light-danger" : "light-warning";
          break;

        case "dispute":
          text = "light-warning";
          break;

        default:
          text = "light-info";
          break;
      }

      return text;
    },
  },
};
</script>
